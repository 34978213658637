import * as u from '@jsmanifest/utils'
import React from 'react'
import { RouteComponentProps } from '@reach/router'
import produce from 'immer'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import axios from 'axios'
import { toast } from 'react-toastify'
import Titles, { TitlesProps } from 'admin/components/Titles'

export type ProjectbreezeProps = React.PropsWithChildren<
  RouteComponentProps<{ id?: string }> & {}
>

function Projectbreeze(props: React.PropsWithChildren<ProjectbreezeProps>) {
  const [helperText, setHelperText] = React.useState('')
  const [titles, setTitles] = React.useState<string[]>([])
  const [selectedItemToDelete, setSelectedItemToDelete] = React.useState<
    string | null
  >('')

  const addTitle = React.useCallback(
    async (title = '') => {
      try {
        title = title.toUpperCase()
        if (!titles.includes(title)) {
          setTitles(produce((draft) => void draft.push(title)))
          helperText && setHelperText('')

          await axios({
            method: 'post',
            url: `/api/titles`,
            params: { title: title?.toUpperCase?.() || '' },
          })
        } else {
          setHelperText(`"${title}" already exists`)
        }
      } catch (error) {
        setTitles(titles)
        const err = error instanceof Error ? error : new Error(String(error))
        toast.error(err.message, { position: 'bottom-right' })
      }
    },
    [helperText, titles],
  )

  const deleteTitle = React.useCallback(
    async (title = '') => {
      try {
        setTitles(
          produce((draft) => {
            const index = draft.indexOf(title)
            if (index > -1) draft.splice(index, 1)
          }),
        )
        await axios({ method: 'delete', url: `/api/titles`, params: { title } })
        setSelectedItemToDelete('')
      } catch (error) {
        setTitles(titles)
        setSelectedItemToDelete(selectedItemToDelete)
        const err = error instanceof Error ? error : new Error(String(error))
        toast.error(err.message, { position: 'bottom-right' })
      }
    },
    [selectedItemToDelete, titles],
  )

  const renderModal: TitlesProps['renderModal'] = React.useCallback(
    ({ inputRef }) => (
      <Box
        component="form"
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100%"
        onSubmit={(evt: React.FormEvent<HTMLFormElement>) => {
          evt.preventDefault()
          deleteTitle(selectedItemToDelete)
        }}
        onKeyUp={(evt: React.KeyboardEvent) => {
          if (evt.key === 'Enter') {
            deleteTitle(selectedItemToDelete)
          }
        }}
      >
        <Box width="500px" margin="auto">
          <Typography align="center" variant="h5">
            Are you sure you want to delete{' '}
            <strong>{selectedItemToDelete}</strong>
          </Typography>
          <Box
            padding={2}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Button type="submit" color="primary" variant="contained">
              Yes
            </Button>
            <span style={{ width: 6 }} />
            <Button
              onClick={() => {
                setSelectedItemToDelete('')
                inputRef.current.focus()
              }}
              variant="contained"
            >
              No
            </Button>
          </Box>
        </Box>
      </Box>
    ),
    [deleteTitle, selectedItemToDelete],
  )

  React.useEffect(() => {
    axios
      .get(`/api/titles`)
      .then(({ data: { titles = [] } }) => setTitles(titles))
      .catch((error) => {
        const err = error instanceof Error ? error : new Error(String(error))
        toast.error(`[${err.name}] ${err.message}`, {
          position: 'bottom-right',
        })
      })
  }, [])

  return (
    <Box p={3}>
      <Titles
        helperText={helperText}
        titles={titles}
        modalOpened={!!selectedItemToDelete}
        onSelect={setSelectedItemToDelete}
        onSubmit={addTitle}
        renderModal={renderModal}
      />
    </Box>
  )
}

export default Projectbreeze
