import * as u from '@jsmanifest/utils'
import React from 'react'
import axios from 'axios'
import produce, { Draft } from 'immer'
import { Router, RouteComponentProps } from '@reach/router'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import Divider from '@mui/material/Divider'
import Button from '@mui/material/Button'
import { PostFrontmatter } from 'api'
import { toastError, toastSuccess } from '../../../utils'
import Icon from '../../../components/common/Icon'
import Input from '../../components/common/Input'
import Assets from './Assets'
import Metadata, { MetadataProps } from './Metadata'
import * as t from './types'

export type AdminPostPageProps = React.PropsWithChildren<
  RouteComponentProps<{ id?: string }> & {}
>

export const initialState = {
  data: {
    title: '',
    description: '',
    date: '',
    lastUpdated: '',
    thumbnail: '',
    author: '',
    category: '',
    tags: [] as string[],
    pageType: 'post',
  },
}

function AdminPostPage(props: AdminPostPageProps) {
  const [slug, setSlug] = React.useState('')
  const [state, setState] = React.useState(initialState)
  const [content, setContent] = React.useState('')
  const { id } = props

  const set = React.useCallback(
    (
      fn:
        | Partial<Draft<typeof initialState>>
        | ((draft: Draft<typeof initialState>) => void),
    ) => {
      setState(
        produce((draft) => {
          if (u.isFnc(fn)) fn(draft)
          else u.assign(draft, fn)
        }),
      )
    },
    [],
  )

  const onPaste: React.ClipboardEventHandler<HTMLInputElement> =
    React.useCallback(
      async (evt) => {
        try {
          const pastedContent = evt.clipboardData.getData('text/plain')
          const { data } = await axios.post<PostFrontmatter>(
            process.env.NODE_ENV === 'development'
              ? `http://127.0.0.1:4000/post`
              : 'https://jsmanifest.com/.netlify/functions/post',
            pastedContent,
          )
          if (u.isObj(data)) {
            set({ data } as { data: t.State['data'] })
          } else {
            toastError(
              `The data from the response was not an object. Received ${typeof data} instead`,
            )
          }
        } catch (error) {
          console.error(error)
          toastError(error.message)
        }
      },
      [slug],
    )

  const onMetadataFieldChange: MetadataProps['onChange'] = React.useCallback(
    (evt) =>
      set((draft) => {
        ;(draft.data as any)[evt.target.name] = evt.target.value
      }),
    [],
  )

  return (
    <Box>
      <Box>
        <TextField
          helperText="Slug"
          variant="outlined"
          name="slug"
          value={slug}
          onChange={(e) => setSlug(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Box>
      <Box>
        <TextField
          helperText="Paste content"
          variant="outlined"
          value=""
          onPaste={onPaste}
        />
      </Box>
      <div style={{ height: 25, width: '100%' }} />
      <Box>
        <Assets />
      </Box>
      <Box>
        <Metadata fields={state.data} onChange={onMetadataFieldChange} />
      </Box>
      <div style={{ height: 25, width: '100%' }} />
      <Box>
        <Typography variant="h5" gutterBottom>
          Preview
        </Typography>
      </Box>
    </Box>
  )
}

export default AdminPostPage
