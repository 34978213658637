import React from 'react'
import { ClassNames } from '@emotion/react'
import Drawer, { DrawerProps } from '@mui/material/Drawer'
import List from '@mui/material/List'
import { RouteComponentProps } from '@reach/router'
import { SidebarItem as ISidebarItem } from 'admin/types'
import useTheme from '@mui/material/styles/useTheme'
import SidebarItem from './SidebarItem'
import renderItem from './renderItem'

export interface SidebarProps {
  opened: boolean
  onClose: DrawerProps['onClose']
  items: ISidebarItem[]
  onClick(
    e: React.MouseEvent<any, MouseEvent>,
    item: ISidebarItem,
    index: number,
  ): void
  highlightedItem?: string
  selectedItem?: string
}

function AdminSidebar(props: RouteComponentProps<any> & SidebarProps) {
  const {
    opened,
    onClose,
    items = [],
    onClick: onClickProp,
    highlightedItem,
    selectedItem,
  } = props

  const theme = useTheme()

  const onClick = React.useCallback(
    (item: ISidebarItem, index: number) =>
      (e: React.MouseEvent<any, MouseEvent>) =>
        onClickProp(e, item, index),
    [],
  )

  return (
    <ClassNames>
      {({ css }) => (
        <Drawer
          classes={{
            root: css`
              width: 240px;
              flex-shrink: 0;
              ${theme.breakpoints.down('md')} {
                width: ${opened ? 240 : 0}px;
              }
            `,
            paper: css`
              width: 240px;
              background: ${theme.palette.background.dark};
              ${theme.breakpoints.down('md')} {
                width: ${opened ? 240 : 0}px;
              }
            `,
          }}
          variant="permanent"
          anchor="left"
          open={opened}
          onClose={onClose}
        >
          <div style={theme.mixins.toolbar} />
          <List disablePadding>
            {items.map((item, index: number) =>
              renderItem(
                <SidebarItem
                  key={item.to || item.name}
                  highlightedItem={highlightedItem}
                  selectedItem={selectedItem}
                  onClick={onClick}
                  index={index}
                  depth={0}
                  item={item}
                />,
                item,
              ),
            )}
          </List>
        </Drawer>
      )}
    </ClassNames>
  )
}

export default AdminSidebar
