import * as u from '@jsmanifest/utils'
import React from 'react'
import { css } from '@emotion/react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Modal from '@mui/material/Modal'
import TextField from '@mui/material/TextField'
import Downshift from 'downshift'

export interface TitlesProps {
  titles: string[]
  helperText?: string
  modalOpened?: boolean
  onSelect?: (title: string | null) => void
  onSubmit?: (title: string) => void
  renderModal?: (args: {
    inputRef: React.RefObject<HTMLInputElement>
  }) => React.ReactElement
}

function isButtonSubmit(evt: any): evt is React.FormEvent<HTMLFormElement> {
  return 'elements' in evt.target
}

function Titles({
  modalOpened,
  titles = [],
  helperText = '',
  onSubmit: onSubmitProp,
  onSelect: onSelectProp,
  renderModal,
}: React.PropsWithChildren<TitlesProps>) {
  const [value, setValue] = React.useState('')
  const inputRef = React.useRef<HTMLInputElement>(null)

  const onSubmit = React.useCallback(
    (evt: React.FormEvent) => {
      evt.preventDefault()

      let currentValue = ''
      let inputEl: HTMLInputElement | null = null

      if (isButtonSubmit(evt)) {
        inputEl = Array.from(evt.currentTarget.elements).find(
          (el) => 'value' in el,
        ) as HTMLInputElement
        currentValue = inputEl.value
      } else {
        currentValue = (evt.target as any).value
      }

      setValue(
        currentValue.includes('-')
          ? currentValue.substring(0, currentValue.indexOf('-') + 1)
          : currentValue,
      )

      onSubmitProp?.(currentValue)
    },
    [onSubmitProp],
  )

  React.useEffect(() => void (!modalOpened && setValue('')), [modalOpened])

  return (
    <>
      <Downshift
        inputValue={value}
        isOpen
        onInputValueChange={(item) => setValue(item || '')}
        onSelect={onSelectProp}
      >
        {({
          getInputProps,
          getItemProps,
          getMenuProps,
          isOpen,
          inputValue,
          highlightedIndex,
          selectedItem,
          getRootProps,
        }) => {
          const listItems = isOpen
            ? u.reduce(
                titles,
                (acc, title, index) =>
                  !inputValue ||
                  title.toUpperCase().includes(inputValue.toUpperCase())
                    ? acc.concat(
                        <li
                          {...getItemProps({
                            key: title + index,
                            index,
                            item: title,
                            style: {
                              backgroundColor:
                                highlightedIndex === index
                                  ? 'lightgray'
                                  : 'white',
                              fontWeight:
                                selectedItem === title ? 'bold' : 'normal',
                            },
                          })}
                          css={css`
                            padding-top: 0px;
                            padding-bottom: 0px;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                          `}
                        >
                          {title}
                        </li>,
                      )
                    : acc,
                [] as React.ReactElement[],
              )
            : null

          const totalListItems = listItems?.length || 0
          const totalTitles = titles.length
          const ratio = `${totalListItems} / ${totalTitles}`

          return (
            <form name="titles" onSubmit={onSubmit}>
              <Box
                display="flex"
                alignItems="flex-end"
                {...getRootProps({ refKey: 'ref' }, { suppressRefError: true })}
              >
                <TextField
                  ref={inputRef}
                  helperText={helperText}
                  label={ratio}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  placeholder="Title"
                  size="small"
                  variant="standard"
                  {...getInputProps()}
                />
                <span style={{ width: 10 }} />
                <Button
                  onSubmit={onSubmit}
                  type="submit"
                  color="primary"
                  size="small"
                  variant="contained"
                >
                  Save
                </Button>
              </Box>
              <ul
                {...getMenuProps({ style: { listStyle: 'none', padding: 0 } })}
              >
                {listItems}
              </ul>
            </form>
          )
        }}
      </Downshift>
      {renderModal && (
        <Modal open={!!modalOpened}>{renderModal({ inputRef })}</Modal>
      )}
    </>
  )
}

export default Titles
