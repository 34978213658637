import React from 'react'
import { ClassNames } from '@emotion/react'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import useTheme from '@mui/material/styles/useTheme'
import Icon from 'components/common/Icon'
import { SidebarItem as ISidebarItem } from 'admin/types'
import renderItem from './renderItem'

export interface SidebarItemProps {
  item: ISidebarItem
  onClick: (
    item: ISidebarItem,
    index: number,
  ) => (e: React.MouseEvent<any, MouseEvent>) => void
  highlightedItem?: string
  selectedItem?: string
  depth?: number
  index?: number
}

function SidebarItem(props: React.PropsWithChildren<SidebarItemProps>) {
  const {
    item,
    highlightedItem,
    selectedItem,
    onClick,
    depth = 0,
    index,
  } = props
  const theme = useTheme()

  const otherListItemProps = {} as any

  if ((item.to && item?.items?.length) || !item?.items?.length) {
    otherListItemProps.button = true
    otherListItemProps.disableRipple = true
    otherListItemProps.disableTouchRipple = true
  }

  return (
    <ClassNames>
      {({ css }) => (
        <>
          <ListItem
            id={item.name}
            classes={{
              container: css`
                width: ${depth * 2} !important;
              `,
              root: css`
                transition: all 0.4s ease;
                :hover {
                  .text {
                    color: ${theme.palette.primary.dark};
                  }
                }
              `,
              selected: css`
                border-left: 4px solid ${theme.palette.info.dark};
              `,
            }}
            onClick={
              item.items?.length
                ? item.to
                  ? // This is for lists that have subitems but still
                    // intends to do something when being clicked
                    onClick(item, index as number)
                  : // Disable onClick for lists that have subitems that don't
                    // intended to do anything but to display their label
                    undefined
                : // Attach onClick for all other (normal) sidebar items
                  onClick(item, index as number)
            }
            selected={item.name === selectedItem}
            {...otherListItemProps}
          >
            {item.icon && (
              <ListItemIcon>
                {item.icon && (
                  <Icon
                    name={item.icon}
                    style={{ width: 30 - depth * 2, height: 30 - depth * 2 }}
                  />
                )}
              </ListItemIcon>
            )}
            <ListItemText
              style={{
                userSelect: !item.to && item.items?.length ? 'none' : 'auto',
              }}
              primary={item.label}
              primaryTypographyProps={{
                classes: {
                  root: css`
                    ${item.items?.length ? `color: #fff;` : ''}
                    ${!item.items?.length
                      ? `color: rgba(255, 255, 255, 0.9) !important;`
                      : ''}
                  `,
                },
              }}
            />
          </ListItem>
          {item.items?.length ? (
            <ListItem dense>
              <List dense disablePadding style={{ width: '100%' }}>
                {Array.isArray(item.items) &&
                  item.items.map((innerItem, i) =>
                    renderItem(
                      <SidebarItem
                        key={innerItem.name}
                        highlightedItem={highlightedItem}
                        selectedItem={selectedItem}
                        depth={depth + 2}
                        onClick={onClick}
                        index={i}
                        item={innerItem}
                      />,
                      innerItem,
                    ),
                  )}
              </List>
            </ListItem>
          ) : null}
        </>
      )}
    </ClassNames>
  )
}

export default SidebarItem
