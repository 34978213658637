import React from 'react'
import partialRight from 'lodash/partialRight'
import LazyLoad from 'react-lazyload'
import List from '@mui/material/List'
import Row from './Row'
import * as t from './types'

export interface BlogPostsRowsProps {
  onClick(
    e: React.MouseEvent<any>,
    post: t.BlogPostsState['dialog']['viewing']['post'],
  ): void
  posts: t.BlogPostsPageProps['posts']
  searchKeyword: string
}

function BlogPostsRows({
  onClick,
  posts,
  searchKeyword = '',
}: React.PropsWithChildren<BlogPostsRowsProps>) {
  const regex = React.useMemo(
    () => new RegExp(searchKeyword, 'i'),
    [searchKeyword],
  )

  const filteredPosts = posts.filter((post) => {
    if (regex.test(post.frontmatter.title)) return true
    const titleParts = post.frontmatter.title
      .split(' ')
      .map((part) => part.toLowerCase())
    return titleParts.some((part) => regex.test(part))
  })

  return (
    <List>
      {filteredPosts.map(
        ({
          id,
          fields: { slug },
          frontmatter,
          ...rest
        }: BlogPostsRowsProps['posts'][number]) => (
          <LazyLoad key={id}>
            <Row
              onClick={partialRight(onClick, {
                id,
                slug,
                ...frontmatter,
              })}
              {...frontmatter}
              {...rest}
            />
          </LazyLoad>
        ),
      )}
    </List>
  )
}

export default React.memo(
  BlogPostsRows,
  (props, nextProps) => props.searchKeyword === nextProps.searchKeyword,
)
