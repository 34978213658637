import React from 'react'
import { RouteComponentProps } from '@reach/router'
import Typography from '@mui/material/Typography'

export type AdminOverviewPageProps = React.PropsWithChildren<
  RouteComponentProps<any> & {}
>

function AdminOverviewPage(props: AdminOverviewPageProps) {
  return (
    <Typography className="text">
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus quam dicta,
      consequuntur, vel quaerat ex, veniam error corrupti eum quisquam
      voluptatibus aliquid. Magnam nesciunt minus quis ex dolor unde atque!
    </Typography>
  )
}

export default AdminOverviewPage
