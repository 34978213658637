import * as u from '@jsmanifest/utils'
import React from 'react'
import merge from 'lodash/merge'
import produce from 'immer'
import netlifyIdentity, { User } from 'netlify-identity-widget'
import { Router, RouteComponentProps } from '@reach/router'
import { PageProps } from 'gatsby'
import { AdminState, AdminContext } from 'admin/types'
import Loading from 'components/common/Loading'
import useLogo from 'hooks/useLogo'
import Portal, { AdminProvider } from '../admin'

export const initialState = {
  activePopup: null as null | string,
  deviceSize: 'normal' as 'small' | 'normal',
  authed: null as null | boolean,
  user: null as null | User,
  dialog: {
    opened: false,
    id: '',
    props: {} as Record<string, any> & {
      onSubmit?<Values extends {} = {}>(
        event: React.SyntheticEvent<HTMLFormElement>,
        values?: Values,
      ): any
    },
    title: '',
    subtitle: '',
  },
  sidebar: {
    opened: true,
    highlightedItem: 'overview',
    selectedItem: 'overview',
  },
}

function AdminPage({ location, navigate }: PageProps) {
  const [state, setState] = React.useState(initialState)
  const logo = useLogo()

  const isLoginPage = React.useCallback(
    () => location.pathname.endsWith('/login'),
    [],
  )

  const set = React.useCallback((fn: Parameters<AdminContext['set']>[0]) => {
    setState(
      produce((draft) => void (u.isFnc(fn) ? fn(draft) : merge(draft, fn))),
    )
  }, [])

  const ctx = React.useMemo(
    () => ({
      ...state,
      onSidebarClick: (name: string) =>
        set({ sidebar: { selectedItem: name } }),
      dialog: {
        ...state?.dialog,
        open: (options) => set({ dialog: { ...options, opened: true } }),
        close: () => set({ dialog: initialState.dialog }),
      },
      logout: () => netlifyIdentity.logout(),
      set,
      sidebar: {
        ...state?.sidebar,
        open: (opts?: Partial<AdminState['sidebar']>) =>
          set({ sidebar: { opened: true, ...opts } }),
        close: () => set({ sidebar: { opened: false } }),
        setHighlighted: (name = '') =>
          set({ sidebar: { highlightedItem: name } }),
        setSelected: (name = '') => set({ sidebar: { selectedItem: name } }),
      },
      user: state?.user,
    }),
    [state, location],
  ) as AdminContext

  React.useEffect(() => {
    netlifyIdentity.on('init', (u) => !u && set({ authed: false }))
    // @ts-expect-error
    netlifyIdentity.on('open', (name: string) => set({ activePopup: name }))
    netlifyIdentity.on('close', () => set({ activePopup: null }))
    netlifyIdentity.on('logout', () => window.location.replace('/admin/login'))
    netlifyIdentity.on('login', (_user) => {
      // Navigate the authed admin straight to the dashboard if they're on the login page
      !!_user?.app_metadata.roles.includes('admin')
        ? set({ authed: true, user: _user })
        : set({ authed: false, user: null })
      netlifyIdentity.close()
    })

    netlifyIdentity.on('error', (err) => {
      console.error(err)
      set({ authed: false, user: null })
      !isLoginPage() && window.location.replace('/admin/login')
    })

    netlifyIdentity.init({
      APIUrl: 'https://jsmanifest.com/.netlify/identity',
      logo: !!logo.src,
    })
  }, [])

  React.useEffect(() => {
    if (!state) return
    if (u.isBool(state?.authed)) {
      if (!state.authed) {
        if (state.activePopup !== 'login') netlifyIdentity.open('login')
        if (!isLoginPage()) navigate('/admin/login')
      } else {
        if (isLoginPage()) navigate('/admin')
        if (state.activePopup) netlifyIdentity.close()
      }
    }
  }, [state?.authed, state?.activePopup])

  const Login: React.FC<RouteComponentProps<any>> = React.memo(
    ({ children }) => <>{children || null}</>,
    () => true,
  )

  return (
    <AdminProvider value={ctx}>
      {!u.isBool(state?.authed) ? (
        <Loading size={25} />
      ) : (
        <Router basepath="/admin/*" location={location}>
          <Login path="/login" redirect default />
          <ProtectedRoute path="/" authed={state.authed} component={Portal} />
        </Router>
      )}
    </AdminProvider>
  )
}

function ProtectedRoute({
  component: Component,
  authed,
  ...rest
}: React.PropsWithChildren<{ authed: boolean | null }> &
  RouteComponentProps<any>) {
  const { location, navigate } = rest
  if (authed === null) return null
  if (authed === false) {
    if (location?.pathname !== '/admin/login') navigate?.('/admin/login')
    return null
  }
  return <Component {...rest} />
}

export default AdminPage
