import React, { ForwardedRef } from 'react'
import { css } from '@emotion/react'
import Button, { ButtonProps } from '@mui/material/Button'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import Typography from '@mui/material/Typography'

export interface DeleteItemDialogProps<T = any> {
  id: string | number
  title?: string
  subtitle?: string
  onSubmit?(e: React.SyntheticEvent, id?: T): Promise<any>
  onClose?(): void
  maxWidth?: string | number
}

function DeleteItemDialog({
  id,
  title = '',
  subtitle = '',
  onSubmit: onSubmitProp,
  onClose,
  maxWidth,
}: React.PropsWithChildren<DeleteItemDialogProps>) {
  const yesBtnRef = React.useRef<HTMLButtonElement>()
  const [error, setError] = React.useState<
    null | (Error & { networkError: any })
  >(null)

  async function onSubmit(e: React.SyntheticEvent) {
    e.preventDefault()
    try {
      const result = await onSubmitProp?.(e, id)
      if (result) {
        const logMsg = '%c[DeleteItem][onSubmit] Result'
        const logStyle = 'color:#00b406;font-weight:bold;'
        console.log(logMsg, logStyle, result)
      }
      return result
    } catch (error) {
      console.error(error)
      setError(error)
    }
  }

  React.useEffect(() => {
    yesBtnRef.current?.focus()
  }, [])

  const ActionButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
    (props, ref) => (
      <Button
        ref={ref}
        color="inherit"
        variant="outlined"
        type="button"
        {...props}
      />
    ),
  )

  return (
    <form
      css={css`
        padding: 12px;
        max-width: ${maxWidth || '350px'};
      `}
      onSubmit={onSubmit}
    >
      {error ? (
        error.networkError?.result?.errors?.length ? (
          <Typography component="ul" style={{ listStyleType: 'none' }}>
            {error.networkError.result.errors.map(({ message }: any = {}) => (
              <Typography component="li" key={message} color="error">
                {message}
              </Typography>
            ))}
          </Typography>
        ) : (
          error.message || (
            <Typography color="error" gutterBottom>
              {error?.message}
            </Typography>
          )
        )
      ) : null}
      {title && (
        <DialogTitle
          css={css`
            text-align: center;
          `}
        >
          {title}
        </DialogTitle>
      )}
      {subtitle && (
        <Typography variant="subtitle2" align="center">
          {subtitle}
        </Typography>
      )}
      <DialogActions
        css={css`
          display: block;
          text-align: center;
        `}
      >
        <ActionButton
          ref={yesBtnRef as ForwardedRef<HTMLButtonElement>}
          type="submit"
        >
          Yes
        </ActionButton>
        <ActionButton onClick={onClose}>No</ActionButton>
      </DialogActions>
    </form>
  )
}

export default DeleteItemDialog
