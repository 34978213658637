export const modalId = {
  addItem: 'add.item',
  deleteItem: 'delete.item',
  updateItem: 'update.item',
  addResourcesSection: 'add.resources.section',
  deleteResourcesSection: 'delete.resources.section',
  updateResourcesSection: 'update.resources.section',
  addProject: 'add.project',
  deleteProject: 'delete.project',
  updateProject: 'update.project',
  viewPostsBucketObjects: 'view.posts.bucket.objects',
} as const
