import React from 'react'
import { css } from '@emotion/react'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import Typography from '@mui/material/Typography'
import * as t from './types'

function AdminBlogPostRow({
  title = '',
  description = '',
  date = '',
  thumbnail = '',
  onClick,
}: t.BlogPostsRowProps) {
  return (
    <ListItem
      css={css`
        border: 1px solid rgba(0, 0, 0, 0);
        transition: all 0.2s ease-out;
        :hover {
          cursor: pointer;
          border: 1px solid #000;
        }
      `}
      onClick={onClick}
    >
      <ListItemAvatar>
        <Avatar src={thumbnail} />
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <Box display="flex" flexDirection="column">
            <Typography variant="caption">{description}</Typography>
            <Typography variant="caption">{date}</Typography>
          </Box>
        }
        primaryTypographyProps={{
          className: css`
            color: #fff;
          `,
          variant: 'body2',
        }}
        secondaryTypographyProps={{
          className: css`
            color: #ccc;
          `,
          variant: 'caption',
        }}
      />
    </ListItem>
  )
}

export default React.memo(AdminBlogPostRow)
