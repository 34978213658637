import * as u from '@jsmanifest/utils'
import React from 'react'
import { css } from '@emotion/react'
import useTheme from '@mui/material/styles/useTheme'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import * as t from './types'

export interface MetadataProps {
  fields: t.State['data']
  onChange(evt: React.ChangeEvent<HTMLInputElement>): void
}

function Metadata(props: React.PropsWithChildren<MetadataProps>) {
  const theme = useTheme()
  const { fields: fieldsProp, onChange } = props
  const fields = React.useMemo(() => u.keys(fieldsProp), [])
  return (
    <>
      {fields.map((name) => {
        const value = fieldsProp[name as keyof t.State['data']] || ''
        const isPopulated = u.isArr(value) ? value.length > 0 : !!value
        return (
          <Box key={name} pb={1}>
            <TextField
              name={name}
              label={name}
              InputProps={{
                classes: {
                  notchedOutline: isPopulated
                    ? css`
                        border-color: ${theme.palette.success.main};
                      `
                    : '',
                },
              }}
              InputLabelProps={{
                classes: {
                  formControl: isPopulated
                    ? css`
                        color: ${theme.palette.success.main};
                      `
                    : '',
                  outlined: css`
                    font-weight: 500;
                  `,
                },
                shrink: true,
              }}
              value={value}
              onChange={onChange}
              variant="outlined"
              margin="dense"
              fullWidth
            />
          </Box>
        )
      })}
    </>
  )
}

export default Metadata
