import * as u from '@jsmanifest/utils'
import { toast } from 'react-toastify'
import { RouteComponentProps } from '@reach/router'
import React from 'react'
import axios from 'axios'
import { useStaticQuery, graphql, navigate } from 'gatsby'
import produce, { Draft } from 'immer'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Pagination from '@mui/lab/Pagination'
import PaginationItem from '@mui/lab/PaginationItem'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Rows from './Rows'
import * as t from './types'

export const initialState = {
  currentPage: 1,
  dialog: {
    active: '',
    viewing: {
      type: 'post',
      post: {} as t.BlogPostObject['frontmatter'] & {
        id: string
        slug: string
      },
    },
  },
}

export function AdminBlogPostsPage({
  itemsPerPage = 25,
  posts = [],
  pageInfo,
}: React.PropsWithChildren<t.BlogPostsPageProps> & RouteComponentProps) {
  const [searchTerm, setSearchTerm] = React.useState('')
  const thumbnailInputRef = React.useRef<any>()
  const [state, setState] = React.useState(initialState)

  const set = React.useCallback(
    (
      fn:
        | Partial<Draft<typeof initialState>>
        | ((draft: Draft<typeof initialState>) => void),
    ) => {
      setState(
        produce((draft) => {
          if (u.isFnc(fn)) fn(draft)
          else u.assign(draft, fn)
        }),
      )
    },
    [],
  )

  function onPageChange(e: React.ChangeEvent<any>, selectedPage: any) {
    const nextPg = selectedPage === 1 ? '' : selectedPage
    navigate(`/blog-posts/${nextPg}`)
  }

  const pagination = (
    <Pagination
      boundaryCount={2}
      onChange={onPageChange}
      count={pageInfo.pageCount}
      hideNextButton={!pageInfo.hasNextPage}
      hidePrevButton={!pageInfo.hasPreviousPage}
      page={pageInfo.currentPage}
      siblingCount={3}
      renderItem={(params) => <PaginationItem {...params} />}
      variant="text"
      showFirstButton
      showLastButton
    />
  )

  const onRowClick = React.useCallback(
    (
      e: React.MouseEvent<any>,
      post: t.BlogPostsState['dialog']['viewing']['post'],
    ) => {
      set((draft) => {
        draft.dialog.active = post.id
        draft.dialog.viewing.post = post
      })
    },
    [],
  )

  const selectThumbnail = React.useCallback(
    (post: t.BlogPostsState['dialog']['viewing']['post']) =>
      (e: React.SyntheticEvent<HTMLInputElement>): Promise<void> => {
        console.log(post)
        return new Promise(async (resolve, reject) => {
          const file = (e.target as any).files[0] as File
          const fileReader = new FileReader()

          if (!file.type.includes('image')) {
            toast.error(
              `The file "${file.name}" (${file.type}) is not an image`,
              { position: 'bottom-right' },
            )
            reject()
          }

          fileReader.onload = async () => {
            const dataURI = fileReader.result as string
            const pathInBucket = post.thumbnail.substring(
              post.thumbnail.indexOf('posts/'),
            )
            const response = await axios.post(
              `/.netlify/functions/post`,
              JSON.stringify({
                thumbnail: {
                  pathInBucket,
                  dataURI,
                },
              }),
            )
            set((draft) => void (draft.dialog.viewing.post.thumbnail = dataURI))
            resolve()
          }

          fileReader.onerror = reject
          fileReader.readAsDataURL(file)
        })
      },
    [],
  )

  return (
    <Typography component="div">
      {pagination}
      <TextField
        label="Enter search keyword"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        margin="normal"
      />
      <Rows onClick={onRowClick} posts={posts} searchKeyword={searchTerm} />
      {pagination}
      <Dialog
        open={!!state.dialog.active}
        onClose={() => set((draft) => void (draft.dialog.active = ''))}
      >
        <DialogContent>
          <Box margin="8px auto">
            <Typography
              variant="h6"
              align="center"
              style={{ margin: '6px auto' }}
            >
              {state.dialog.viewing.post.title}
            </Typography>
            <Box display="flex" justifyContent="space-around">
              <Typography variant="caption">
                Created: {state.dialog.viewing.post.date}
              </Typography>
              <Typography variant="caption">
                Updated: {state.dialog.viewing.post.lastUpdated}
              </Typography>
            </Box>
            <Typography variant="body2" style={{ margin: '10px auto' }}>
              {state.dialog.viewing.post.description}
            </Typography>
            <input
              onChange={selectThumbnail(state.dialog.viewing.post)}
              type="file"
              ref={thumbnailInputRef}
              style={{ display: 'none' }}
            />
            <img
              src={state.dialog.viewing.post.thumbnail || ''}
              alt={state.dialog.viewing.post.title || ''}
              style={{
                width: '100%',
                height: '100%',
                maxHeight: 200,
                margin: '10px auto',
                objectFit: 'scale-down',
              }}
            />
            <Box display="flex" alignItems="center" justifyContent="center">
              <Button onClick={() => thumbnailInputRef.current.click()}>
                Change thumbnail
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </Typography>
  )
}

export default (props: React.PropsWithChildren<RouteComponentProps>) => {
  const data = useStaticQuery(graphql`
    {
      allMarkdownRemark(
        limit: 25
        sort: { order: DESC, fields: frontmatter___date }
      ) {
        nodes {
          id
          frontmatter {
            title
            date(fromNow: true)
            lastUpdated(fromNow: true)
            description
            thumbnail
            author
            tags
            category
            pageType
            sources
          }
          fields {
            slug
          }
        }
        pageInfo {
          currentPage
          hasNextPage
          hasPreviousPage
          itemCount
          pageCount
          perPage
          totalCount
        }
      }
    }
  `)

  return (
    <AdminBlogPostsPage
      posts={data.allMarkdownRemark.nodes}
      pageInfo={data.allMarkdownRemark.pageInfo}
      {...props}
    />
  )
}
