import React from 'react'
import { css } from '@emotion/react'
import Box from '@mui/material/Box'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'

export interface EditItemDialogProps<FormValues extends {} = any> {
  title?: string
  subtitle?: string
  initialValues?: Partial<FormValues>
  onSubmit?<E extends React.SyntheticEvent<HTMLFormElement>>(
    e: E,
    values?: FormValues,
  ): any
  onClose?(): any
  fields?: TextFieldProps[]
  maxWidth?: string | number
}

function EditItemDialog<FormValues extends {} = any>({
  title,
  subtitle,
  initialValues,
  onSubmit: onSubmitProp,
  fields = [],
  onClose,
  maxWidth,
}: EditItemDialogProps<FormValues>) {
  const [values, setValues] = React.useState<Partial<FormValues>>(
    initialValues || {},
  )
  const [error, setError] = React.useState<
    null | (Error & { networkError: any })
  >(null)

  function onChange(e: React.ChangeEvent<HTMLInputElement>) {
    e.persist()
    setValues((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }))
  }

  async function onSubmit(e: React.SyntheticEvent) {
    try {
      e.preventDefault()
      const result = await onSubmitProp?.(e as any, values as FormValues)
      if (result) {
        const logMsg = '%c[EditItem][onSubmit] Result'
        const logStyle = 'color:#00b406;font-weight:bold;'
        console.log(logMsg, logStyle, result)
      }
    } catch (error) {
      console.error(error)
      setError(error)
    }
  }

  React.useEffect(() => {
    if (initialValues) {
      setValues(initialValues)
    }
  }, [])

  return (
    <form
      css={css`
        padding: 12px;
        max-width: ${maxWidth || '350px'};
      `}
      onSubmit={onSubmit}
    >
      {error ? (
        error.networkError?.result?.errors?.length ? (
          <ul>
            {error.networkError.result.errors.map(({ message }: any = {}) => (
              <Typography component="li" key={message} color="error">
                {message}
              </Typography>
            ))}
          </ul>
        ) : (
          error.message || (
            <Typography color="error" gutterBottom>
              {error?.message}
            </Typography>
          )
        )
      ) : null}
      {title ? (
        <DialogTitle style={{ textAlign: 'center' }}>
          {title}
          <Box>{subtitle}</Box>
        </DialogTitle>
      ) : null}
      {fields.map((field) => (
        <TextField
          key={field.name}
          id={field.name}
          value={values[field.name as keyof FormValues] || ''}
          onChange={onChange}
          fullWidth
          {...field}
        />
      ))}
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <Button type="submit">Submit</Button>
      </DialogActions>
    </form>
  )
}

export default EditItemDialog
