import React from 'react'
import { css } from '@emotion/react'
import { RouteComponentProps } from '@reach/router'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import useTheme from '@mui/material/styles/useTheme'

export interface AdminNavbarProps {
  left?: React.ReactNode
  right?: React.ReactNode
  shifted?: boolean
  isSmallView?: boolean
}

function AdminNavbar(props: AdminNavbarProps & RouteComponentProps<any>) {
  const { left, right, shifted } = props
  const theme = useTheme()

  return (
    <AppBar
      css={css`
        background: #fff;
        margin-left: ${shifted ? 240 : 0}px;
        transition: ${theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        })};
        width: ${shifted ? `calc(100% - 240px)` : '100%'};
      `}
      component="nav"
      color="default"
      position="fixed"
      elevation={0}
    >
      <Toolbar>
        <Typography component="div" noWrap>
          {left}
        </Typography>
        <div style={{ flexGrow: 1 }} />
        <div style={{ display: 'flex', alignItems: 'center' }}>{right}</div>
      </Toolbar>
    </AppBar>
  )
}

export default AdminNavbar
