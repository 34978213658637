import React from 'react'
import { css } from '@emotion/react'
import { RouteComponentProps, Router } from '@reach/router'
import { GatsbyImage } from 'gatsby-plugin-image'
import useTheme from '@mui/material/styles/useTheme'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import useMediaQuery from '@mui/material/useMediaQuery'
import Icon from '../components/common/Icon'
import Navbar from './components/Navbar'
import Sidebar from './components/Sidebar'
import Dialog from './components/Dialog'
import OverviewPage from './pages/Overview'
import PostPage from './pages/Post'
import BlogPostsPage from './pages/BlogPosts'
import BlogPostsDrafts from './pages/BlogPosts/Drafts'
import ProjectbreezePage from './pages/Projectbreeze'
import useAdminCtx from './useAdminCtx'
import useLogo from '../hooks/useLogo'
import * as t from './types'

export interface PortalProps extends RouteComponentProps<any> {
  shifted: boolean
}

function Portal(props: PortalProps) {
  const { logout, sidebar, onSidebarClick } = useAdminCtx()
  const theme = useTheme()
  const logo = useLogo()
  const isSmallView = useMediaQuery(theme.breakpoints.down('md'))

  const sidebarItems: t.SidebarItem[] = [
    { name: 'overview', label: 'Overview', icon: 'home', to: '/admin/' },
    {
      name: 'new-post',
      label: 'New post',
      icon: 'article.post',
      to: '/admin/post/new/',
    },
    {
      name: 'blog-posts',
      label: 'Blog posts',
      icon: 'notes',
      to: '/admin/blog-posts/',
      items: [
        {
          name: 'blog-posts-drafts',
          label: 'Drafts',
          icon: 'medium',
          to: '/admin/blog-posts/drafts/',
        },
      ],
    },
    {
      name: 'pb-titles',
      label: 'Titles (PB)',
      icon: 'extra-content',
      to: '/admin/pb/titles/',
    },
  ]

  const onSidebarItemClick = React.useCallback(
    (e: React.MouseEvent<any>, item: any) => {
      e.persist()
      console.log(`Selected: ${item.name}`, item)
      onSidebarClick(item.name)
    },
    [],
  )

  React.useEffect(() => {
    const findByMatchingPathName = (items: t.SidebarItem[]) => {
      let item: t.SidebarItem | undefined
      for (const obj of items) {
        if (obj.to === props.location?.pathname) {
          item = obj
          break
        }
        if (obj.items) {
          item = findByMatchingPathName(obj.items)
          if (item) break
        }
      }
      return item
    }
    const selectedSidebarItem = findByMatchingPathName(sidebarItems)
    selectedSidebarItem && sidebar.setSelected(selectedSidebarItem.name)
  }, [])

  React.useEffect(() => {
    isSmallView && sidebar.opened
      ? sidebar.close()
      : !isSmallView && !sidebar.opened
      ? sidebar.open()
      : undefined
  }, [isSmallView])

  return (
    <>
      <Navbar
        shifted={sidebar.opened}
        left={
          <Typography color="inherit" variant="h6">
            <GatsbyImage image={logo} alt="jsmanifest logo" />
          </Typography>
        }
        right={
          <>
            {isSmallView ? (
              <IconButton
                onClick={sidebar.opened ? sidebar.close : sidebar.open}
                title="Menu"
                color="inherit"
                size="small"
              >
                <Icon name="menu" />
              </IconButton>
            ) : null}
            <IconButton
              onClick={logout}
              title="logout"
              color="inherit"
              size="small"
            >
              <Icon name="logout" />
            </IconButton>
          </>
        }
      />
      <Sidebar
        opened={!!sidebar?.opened}
        onClose={sidebar?.close}
        onClick={onSidebarItemClick}
        items={sidebarItems}
        highlightedItem={sidebar.highlightedItem}
        selectedItem={sidebar.selectedItem}
      />
      <main
        css={css`
          margin-left: 240px;
          overflow: hidden;
          padding: ${theme.spacing(3)};
          transition: ${theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          })};
          ${theme.breakpoints.down('md')} {
            margin-left: ${props.shifted ? 240 : 0}px;
          }
        `}
      >
        <div style={theme.mixins.toolbar} />
        <Router>
          <OverviewPage path="/" default />
          <PostPage path="/post/*" />
          <BlogPostsPage path="/blog-posts" />
          <BlogPostsDrafts path="/blog-posts/drafts" />
          <ProjectbreezePage path="/pb/*" />
        </Router>
      </main>
      {props.children}
      <Dialog />
    </>
  )
}

export default Portal
