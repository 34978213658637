import React from 'react'
import { Link, navigate } from 'gatsby'
import { SidebarItem as ISidebarItem } from 'admin/types'

function renderSidebarItem(
  sidebarItem: React.ReactElement,
  item: ISidebarItem,
) {
  if (!item.to && item.items?.length) {
    return sidebarItem
  }
  // Instead of nesting an <a> link within an <a>, wrap these with the navigate
  // func to get rid of the console warnings
  if (item.to && item.items?.length) {
    return React.cloneElement(sidebarItem, {
      ...sidebarItem?.props,
      style: { ...sidebarItem?.props?.style, cursor: 'pointer' },
      onClick: (item: any, index: any) => (e: any) => {
        sidebarItem?.props?.onClick(item, index)(e)
        navigate(item.to as string)
      },
    })
  }
  return (
    <Link key={item.to || item.name} to={item.to as string}>
      {sidebarItem}
    </Link>
  )
}

export default renderSidebarItem
