import * as u from '@jsmanifest/utils'
import React from 'react'
import produce, { Draft } from 'immer'
import { RouteComponentProps } from '@reach/router'
import { css } from '@emotion/react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import useTheme from '@mui/material/styles/useTheme'
import { useRecoilState } from 'recoil'
import { draftPostsState } from 'admin/store/postsStore'
import BlogPostCard, {
  Footer as BlogPostCardFooter,
} from 'components/Card/BlogPostCard'

export interface AdminBlogPostsDraftsProps {
  //
}

const initialState = {}

const post = {
  id: 'eeee7320-b3c2-5139-bbed-a2eea26a52e4',
  frontmatter: {
    title: 'The Power of Recursion in JavaScript',
    date: '2 years ago',
    lastUpdated: '2 years ago',
    description:
      'We will talk about the benefits of recursion, why we need it, and show great examples of recursion in use',
    thumbnail:
      'https://jsmanifest.s3-us-west-1.amazonaws.com/posts/the-power-of-recursion-in-javascript/thumbnail.jpg',
    author: 'Christopher T.',
    tags: ['javascript', 'recursion', 'higher order function'],
    category: 'composition',
    pageType: 'post',
    sources: null,
  },
  fields: {
    slug: '/the-power-of-recursion-in-javascript/',
  },
}

function AdminBlogPostsDrafts(
  props: RouteComponentProps<AdminBlogPostsDraftsProps>,
) {
  const [state, setState] = React.useState(initialState)
  const [{ items: drafts = [] }, setDraftPostsState] =
    useRecoilState(draftPostsState)

  const set = React.useCallback(
    (
      fn:
        | Partial<Draft<typeof initialState>>
        | ((draft: Draft<typeof initialState>) => void),
    ) => {
      setState(
        produce((draft) => {
          if (u.isFnc(fn)) fn(draft)
          else u.assign(draft, fn)
        }),
      )
    },
    [],
  )

  React.useEffect(() => {
    fetch(`/api/posts?type=drafts`)
      .then((resp) => resp.json())
      .then(({ items }) => {
        console.log(items)
        setDraftPostsState({ items })
      })
      .catch(console.error)
  }, [])

  return (
    <Box>
      {drafts.map((draft, index) => (
        <BlogPostCard
          key={draft.title || `draft${index + 1}`}
          title={draft.title}
          description={draft.description}
          media={draft.thumbnail}
          footer={
            <BlogPostCardFooter>
              <BlogPostCardFooter.Left>
                <Typography variant="overline">{draft.category}</Typography>
              </BlogPostCardFooter.Left>
            </BlogPostCardFooter>
          }
          transparent
        />
      ))}
    </Box>
  )
}

export default AdminBlogPostsDrafts
