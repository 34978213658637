import * as u from '@jsmanifest/utils'
import React from 'react'
import produce, { Draft } from 'immer'

export interface AdminPostAssetsProps {
  //
}

const initialState = {}

function AdminPostAssets(props: React.PropsWithChildren<AdminPostAssetsProps>) {
  const [state, setState] = React.useState(initialState)

  const set = React.useCallback(
    (
      fn:
        | Partial<Draft<typeof initialState>>
        | ((draft: Draft<typeof initialState>) => void),
    ) => {
      setState(
        produce((draft) => {
          if (u.isFnc(fn)) fn(draft)
          else u.assign(draft, fn)
        }),
      )
    },
    [],
  )

  return <div></div>
}

export default AdminPostAssets
