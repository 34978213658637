import * as u from '@jsmanifest/utils'
import React from 'react'
import produce, { Draft } from 'immer'

export interface AdminPostThumbnailProps {
  //
}

const initialState = {}

// Uploads thumbnail to S3 and addes the copyright/link to the beginning of the post

function AdminPostThumbnail(
  props: React.PropsWithChildren<AdminPostThumbnailProps>,
) {
  const [state, setState] = React.useState(initialState)

  const set = React.useCallback(
    (
      fn:
        | Partial<Draft<typeof initialState>>
        | ((draft: Draft<typeof initialState>) => void),
    ) => {
      setState(
        produce((draft) => {
          if (u.isFnc(fn)) fn(draft)
          else u.assign(draft, fn)
        }),
      )
    },
    [],
  )

  return <div>Thumbnail</div>
}

export default AdminPostThumbnail
