import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import AddItemDialog from 'components/dialogs/AddItem'
import DeleteItemDialog from 'components/dialogs/DeleteItem'
import EditItemDialog from 'components/dialogs/EditItem'
import usePortalCtx from 'admin/useAdminCtx'
import { modalId } from 'admin/constants'

function AdminResourcesDialog() {
  const { dialog } = usePortalCtx()

  const PopupContent = getPopupContent(dialog.id)
  if (!dialog.opened) return null
  if (!PopupContent) return null

  function getPopupContent(dialogId: string) {
    switch (dialogId) {
      case modalId.addItem:
        return () => (
          <AddItemDialog
            title={dialog.title}
            subtitle={dialog.subtitle}
            onClose={dialog.close}
            {...dialog.props}
          />
        )
      case modalId.deleteItem:
        return () => (
          <DeleteItemDialog
            id={dialog.props?.id || ''}
            title={dialog.title}
            subtitle={dialog.subtitle}
            onClose={dialog.close}
            {...dialog.props}
          />
        )
      case modalId.updateItem:
        return () => (
          <EditItemDialog
            title={dialog.title}
            subtitle={dialog.subtitle}
            onClose={dialog.close}
            {...dialog.props}
          />
        )
      default:
        return null
    }
  }

  return (
    <Dialog open={dialog.opened} onClose={dialog.close}>
      <DialogContent>
        <PopupContent />
      </DialogContent>
    </Dialog>
  )
}

export default AdminResourcesDialog
